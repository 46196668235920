import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchPropers(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/propers', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        createProper(ctx, { payload }) {

            let formData = new FormData();
                
            formData.append('bannerImage', payload.bannerImage);
            formData.append('mainImage', payload.mainImage);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'bannerImage' && item[0] !== 'mainImage') formData.append(item[0], item[1]);
            });

            return new Promise((resolve, reject) => {
                axios
                    .post('/propers/create', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProper(ctx, { slug }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/propers/${slug}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchProperCarousel(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/propers/${id}/carousel`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProperCarousel(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/propers/${id}/carousel/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateProper(ctx, { id, payload }) {

            let formData = new FormData();
                
            formData.append('bannerImage', payload.bannerImage);
            formData.append('mainImage', payload.mainImage);

            Object.entries(payload).map(item => 
            {
                if (item[0] !== 'bannerImage' && item[0] !== 'mainImage') formData.append(item[0], item[1]);
            });
            
            return new Promise((resolve, reject) => {
                axios
                    .put(`/propers/${id}/update`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteProper(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/propers/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}